import React from 'react';
import img1 from "./../img/comment.svg"
import img2 from "./../img/user.svg"
import img3 from "./../img/message.svg"
import styled from "styled-components"
import {useTranslation} from "react-i18next";

const ContactsStyled = styled.div`
  padding-top: 72px;
  padding-bottom: 132px;
  display: grid;
  align-items: center;
  grid-template-columns: 544px 1fr;
  grid-gap: 72px;
  
  > form {
    padding: 36px 50px 60px;
    background: #F8E4CB;
    > h2, > h4 {
      text-align: center;
    }
    
    > h4 {
      color: #363432;
      font-weight: 400;
      margin-top: 20px;
    }
    
    > div {
      position: relative;
      margin-top: 25px;
      
      > input, > textarea {
        width: 100%;
        resize: none;
        outline: none;
        padding: 19px 40px 19px 55px;
        border: none;
      }
      
      > textarea {
        height: 110px;
      }
      
      > img {
        position: absolute;
        left: 18px;
        top: 18px;
      }
    }
    
    > button {
      margin-top: 25px;
      text-align: center;
      width: 100%;
      padding: 19px;
      cursor: pointer;
      outline: none;
      background: #95695D;
      color: #fff;
      border: none;
      text-transform: uppercase;
    }

    @media(max-width: 490px) {
      padding: 35px 25px;
    }

  }
  
  > div {
    > a {
      display: block;
      color: #fff;
      font-weight: 300;
      text-decoration: none;
      margin-bottom: 40px;
      font-size: 22px;
      
      &:nth-child(1) {
        margin-bottom: 20px;
      }

      @media (max-width: 651px) {
        text-align: center;
      }

      @media (max-width: 490px) {
        font-size: 18px;
      }
    
    }
  }


  @media(max-width: 985px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 651px) {
    padding-bottom: 72px;
  }

  @media (max-width: 490px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  

`


const Contacts = () => {
    const {t} = useTranslation('common')

    return (
        <ContactsStyled>
            <form action="">
                <h2>{t("contacts.form.title")}</h2>
                <h4>{t("contacts.form.desc")}</h4>
                <div>
                    <img src={img2} alt=""/>
                    <input type="text" placeholder={t("contacts.form.fullName")}/>
                </div>
                <div>
                    <img src={img3} alt=""/>
                    <input placeholder="E-mail*" required type="email"/>
                </div>
                <div>
                    <img src={img1} alt=""/>
                    <textarea placeholder={t("contacts.form.textarea")} name="" id="" cols="30" rows="10"></textarea>
                </div>
                <button>
                    {t("contacts.form.btn")}
                </button>
            </form>
            <div>
                <a></a>
                <a>{t("contacts.address")}</a>
                <a href="tel:992900303030">+992 900 30 30 30</a>
                <a href="mailto:nostalgia@makolli.tj">nostalgia@makolli.tj</a>
            </div>
        </ContactsStyled>
    );
};

export default Contacts;










