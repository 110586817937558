import React from 'react';
import styled from "styled-components";


const BlogStyled = styled.div`
  padding: 72px 0 10px 0;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 46px;
  > img {
    width: 100%;
  }
  
  @media(max-width: 985px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 651px) {
    padding-top: 30px;
    text-align: center;
  }


`


const Blog = ({data}) => {
    return (
        <BlogStyled>
            {data.map(el => el.type === "text" ? <h3 data-aos="fade-right">{el.data}</h3> : <img data-aos="zoom-in" src={el.data} alt=""/>)}
        </BlogStyled>
    );
};

export default Blog;