import React, {useEffect, useState} from 'react';
import {Container} from "./ui/Container";
import logo from "./../img/logo.png"
import bgHeader from "./../img/headerImg.png"
import styled from "styled-components";
import "/node_modules/hamburgers/dist/hamburgers.css";
import {scroller} from "react-scroll";
import {useTranslation} from "react-i18next";


const HeaderStyled = styled.div`
  background: url(${bgHeader}) no-repeat center center / cover;

  > div {
    > nav {
      padding: 37px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      > ul {
        display: flex;
        align-items: center;
        column-gap: 49px;

        > li {
          color: #fff;
          cursor: pointer;
          &:hover {
            color: #F1D9C5;
          }
        }

        @media (max-width: 879px) {
          display: none;
        }
      }

      > div {
        display: none;
        position: relative;
        @media (max-width: 879px) {
          display: block;
        }

        > button {
          background: none;
          border: none;
          outline: none;
          cursor: pointer;

          > span {
            pointer-events: none;
            > span {
              background: #F1D9C5;

              &:after {
                background: #F1D9C5;
              }

              &:before {
                background: #F1D9C5;
              }
            }
          }
        }

        > ul {
          position: absolute;
          background: #f1d9c5;
          padding: 20px;
          right: 0;
          margin-top: 10px;
          border-radius: 20px;
          min-width: 150px;
          z-index: 1;

          > li {
            cursor: pointer;
            line-height: 28px;
          }
        }
      }

      @media (max-width: 490px) {
        > img {
          height: 45px;
        }
      }
    }

    > div {
      padding: 187px 0 350px 0;
      text-align: center;

      @media (max-width: 879px) {
        padding: 87px 0 300px 0;

        text-align: center;

        > h4 {
          max-width: 100% !important;
        }
      }

      @media (max-width: 490px) {
        padding: 47px 0 250px 0;
      }

      > h4 {
        margin: 20px auto 0;
        max-width: 600px;
        font-weight: 300;
        line-height: 30px;
      }
    }
  }

`

const Header = () => {
    const [isToggle, setIsToggle] = useState(false)
    const {t, i18n} = useTranslation('common')


    const scrollTo = (name) => {
        scroller.scrollTo(name, {
            duration: 1000,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    }

    useEffect(() => {
        window.addEventListener('click', (evt) => {
            if (!evt.target.matches(`.menuBurgerLabel`)) {
                setIsToggle(false)
            }
        })
    }, [])

    return (
        <HeaderStyled>
            <Container>
                <nav>
                    <img src={logo} alt=""/>
                    <ul>
                        <li onClick={() => scrollTo("main")}>{t("nav.0")}</li>
                        <li onClick={() => scrollTo("about")}>{t("nav.1")}с</li>
                        <li onClick={() => scrollTo("product")}>{t("nav.2")}</li>
                        <li onClick={() => scrollTo("contacts")}>{t("nav.3")}</li>
                        <li
                            style={{cursor: "pointer", marginLeft: "50px"}}
                            onClick={() => i18n.changeLanguage(i18n.language === 'ru' ? 'en' : 'ru')}
                        >
                            {i18n.language === 'ru' ? 'EN' : 'RU'}
                        </li>
                    </ul>
                    <div>
                        <button
                            onClick={() => setIsToggle(prev => !prev)}
                            className={`menuBurgerLabel hamburger--3dy ${isToggle ? "is-active" : ''}`}
                            type="button"
                        >
                              <span className="hamburger-box">
                                <span className="hamburger-inner"/>
                              </span>
                        </button>

                        {isToggle &&
                            <ul>
                                <li onClick={() => scrollTo("main")}>{t("nav.0")}</li>
                                <li onClick={() => scrollTo("about")}>{t("nav.1")}с</li>
                                <li onClick={() => scrollTo("product")}>{t("nav.2")}</li>
                                <li onClick={() => scrollTo("contacts")}>{t("nav.3")}</li>
                                <li
                                    style={{cursor: "pointer", marginLeft: "12px"}}
                                    onClick={() => i18n.changeLanguage(i18n.language === 'ru' ? 'en' : 'ru')}
                                >
                                    {i18n.language === 'ru' ? 'EN' : 'RU'}
                                </li>
                            </ul>
                        }
                    </div>
                </nav>
                <div>
                    <h1 data-aos="zoom-in">{t("title.0")} <br/> {t("title.1")}</h1>
                    <h4 data-aos="fade-up">{t("title.2")}</h4>
                </div>
            </Container>
        </HeaderStyled>
    );
};

export default Header;