import React from 'react';
import styled from "styled-components";
import {Container} from "./Container";


const TemplateStyled = styled.div`
  padding: 95px 0 0 0;

  > div {
    > h1 {
      margin-left: 50px;

      @media (max-width: 651px) {
        margin-left: 0 !important;
        text-align: center;
      }
    }
  }

  @media (max-width: 490px) {
    padding: 60px 0 0 0;

  }

`

const Template = ({children, title}) => {
    return (
        <TemplateStyled>
            <Container>
                <h1 className="bejoviy">{title}</h1>
                {children}
            </Container>
        </TemplateStyled>
    );
};

export default Template;