import React from 'react';
import b1 from "../img/b1.svg"
import b2 from "../img/b2.svg"
import b3 from "../img/b3.svg"
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const data = [
    {
        id: 1,
        icon: b1,
        title: "крем брюле",
        text: "Ностальгия к прогулкам в парках, \n" +
            "атракционны в соединении с доступными \n" +
            "сладостями с вкусовой линейки Крем Брюле.",
    },
    {
        id: 2,
        icon: b2,
        title: "пломбир",
        text: "Благодаря потребителю а именно аудитории более 300 миллионов - вкус и качество продукции."
    },
    {
        id: 3,
        icon: b3,
        title: "артек",
        text: "От этого многогранность и насыщенность \n" +
            "вкуса вафельных изделий \n" +
            "под знаком \"Артек\"",
    }
]

const BenefitsStyled = styled.div`
  max-width: 1000px;
  margin: -130px auto 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background: #F1D9C5;
  padding: 34px 48px;
  grid-gap: 30px;

  @media (max-width: 701px) {
    grid-template-columns: 1fr;
  }
  
  > div {
    text-align: center;
    
    > img {
      height: 49px;
    }
    > b {
      margin-top: 13px;
      display: block;
      text-transform: uppercase;
    }
    
    > i {
      margin-top: 11px;
      display: block;
      font-size: 15px;
      font-style: normal;
      line-height: 22px;
    }
  }
`


const Benefits = () => {
    const {t} = useTranslation('common')

    return (
        <BenefitsStyled data-aos-easing="linear"
                        data-aos-duration="800" data-aos="fade-up">
            {data.map((el, i) => <div>
                <img src={el.icon} alt=""/>
                <b>{t(`banner.${i}.label`)}</b>
                <i>{t(`banner.${i}.desc`)}</i>
            </div>)}
        </BenefitsStyled>
    );
};

export default Benefits;