import React, {useEffect} from 'react';
import Header from "./components/Header";
import Template from "./components/ui/Template";
import Blog from "./components/ui/Blog";
import img1 from "./img/1.png"
import img2 from "./img/2.png"
import img3 from "./img/3.png"
import img4 from "./img/4.png"
import img5 from "./img/5.png"
import Contacts from "./components/Contacts";
import {Container} from "./components/ui/Container";
import Benefits from "./components/Benefits";
import {Element} from 'react-scroll'
import 'aos/dist/aos.css';
import AOS from 'aos';
import {useTranslation} from "react-i18next";

const App = () => {
    const {t} = useTranslation('common')

    useEffect(() => {
        AOS.init({
            duration : 800,
            once: true
        })
    }, [])

    return (
        <div>
            <Element name="main">
                <Header/>
            </Element>
            <Benefits/>
            <Element name="about">
                <Template title={t("about.title")}>
                    <Blog
                        data={[
                            {
                                type: "text",
                                data: t("about.desc")
                            },
                            {
                                type: "img",
                                data: img1
                            }
                        ]}/>
                </Template>
            </Element>
            <Element name="product">
                <Template title={t("products.title")}>
                    <Blog
                        data={[
                            {
                                type: "img",
                                data: img2
                            },
                            {
                                type: "text",
                                data: t("products.desc.0")
                            },
                            {
                                type: "text",
                                data: t("products.desc.1")
                            },
                            {
                                type: "img",
                                data: img3
                            },
                            {
                                type: "img",
                                data: img4
                            },
                            {
                                type: "text",
                                data: t("products.desc.2")
                            },
                        ]}/>
                </Template>
            </Element>
            <Element name="contacts">
                <Template title={t("contacts.title")}>
                    <Contacts/>
                </Template>
            </Element>
            <footer>
                <Container>
                    {t("contacts.footer")}
                </Container>
            </footer>
        </div>
    );
};

export default App;