import React from 'react';
import styled from "styled-components";


const ContainerStyled = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 30px;
`

export const Container = ({children, ...rest}) => <ContainerStyled {...rest}>
    {children}
</ContainerStyled>


